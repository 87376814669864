import React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const source = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 431 412">
    <path fill="#007BB6" d="M430.117,252.543 L430.117,411.56 L337.929,411.56 L337.929,263.193 C337.929,225.922 324.595,200.486 291.226,200.486 C265.753,200.486 250.594,217.628 243.925,234.21 C241.493,240.138 240.867,248.389 240.867,256.687 L240.867,411.56 L148.648,411.56 C148.648,411.56 149.89,160.275 148.648,134.24 L240.858,134.24 L240.858,173.549 C240.671,173.843 240.428,174.16 240.252,174.445 L240.858,174.445 L240.858,173.549 C253.109,154.68 274.988,127.725 323.96,127.725 C384.633,127.725 430.117,167.361 430.117,252.543 Z M52.183,0.558 C20.635,0.558 0,21.251 0,48.463 C0,75.082 20.038,96.403 50.959,96.403 L51.575,96.403 C83.734,96.403 103.734,75.086 103.734,48.463 C103.128,21.251 83.734,0.558 52.183,0.558 Z M5.477,411.56 L97.661,411.56 L97.661,134.24 L5.477,134.24 L5.477,411.56 Z"/>
  </svg>
);

const Svg = source();
const Icon = createSvgIcon(Svg, 'LinkedIn Logo');
Icon.Svg = Svg;

export default Icon;
