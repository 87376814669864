import React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const source = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 70">
    <path d="M65.709,0.5 C66.879,3.157 67.637,7.57 66.506,10.801 C71.131,14.75 72.721,24.35 70.488,32.195 C77.08,32.698 84.932,31.953 90,33.976 C85.262,33.367 78.791,32.172 72.68,32.588 C71.522,32.667 69.807,32.544 69.891,34.173 C76.965,34.728 83.995,35.333 89.803,37.146 C84.042,36.205 77.049,34.771 69.891,34.572 C66.934,40.407 60.983,43.275 52.768,43.879 C53.651,45.801 55.342,46.532 55.755,49.623 C56.371,54.241 54.791,61.004 56.35,64.082 C57.098,65.557 58.317,65.596 59.139,66.857 C57.127,69.238 52.139,66.589 51.571,64.082 C50.598,59.787 53.053,53.129 50.379,50.217 C50.567,54.902 49.271,61.481 50.578,65.666 C51.093,67.312 52.58,67.947 52.171,69.432 C42.819,70.381 46.728,57.328 45.199,49.622 C43.788,49.723 44.408,51.735 44.403,52.594 C44.363,60.118 45.943,70.438 37.833,69.432 C37.596,67.85 38.921,67.313 39.426,65.869 C40.905,61.636 39.149,55.327 39.827,50.218 C36.732,52.552 40.152,60.698 38.234,64.875 C37.129,67.279 33.568,68.325 30.867,67.252 C31.219,65.462 33.067,65.751 33.851,64.083 C34.947,61.759 33.859,58.409 34.25,54.972 C28.521,56.116 24.077,54.806 21.902,51.208 C20.929,49.593 20.688,47.688 19.512,46.257 C18.342,44.825 16.472,44.632 15.93,42.694 C23.025,40.986 23.297,49.996 29.669,50.218 C31.623,50.289 32.639,49.655 34.447,49.23 C34.953,46.958 36.036,45.263 37.633,44.077 C29.704,42.992 23.156,40.523 20.111,34.573 C12.903,34.927 6.307,35.89 0.198,37.344 C5.743,35.33 12.582,34.608 19.913,34.371 C19.481,31.775 15.694,32.489 13.343,32.589 C8.973,32.781 3.473,33.324 0,33.976 C5.034,32.181 12.098,32.405 18.918,32.391 C16.832,25.851 17.555,15.462 22.104,11.593 C20.815,8.344 21.215,3.03 22.9,0.5 C27.979,0.728 31.059,2.943 34.25,5.057 C38.207,3.932 42.368,3.372 47.79,3.67 C50.07,3.796 52.441,4.821 54.159,4.659 C55.844,4.501 57.701,2.61 59.337,1.888 C61.468,0.947 63.271,0.588 65.709,0.5 Z"/>
  </svg>
);

const Svg = source();
const Icon = createSvgIcon(Svg, 'GitHub Logo');
Icon.Svg = Svg;

export default Icon;
