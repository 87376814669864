import React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const source = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 416">
    <path fill="#03A9F4" d="M512,49.248 C492.96,57.6 472.672,63.136 451.52,65.824 C473.28,52.832 489.888,32.416 497.696,7.808 C477.408,19.904 455.008,28.448 431.136,33.216 C411.872,12.704 384.416,0 354.464,0 C296.352,0 249.568,47.168 249.568,104.992 C249.568,113.312 250.272,121.312 252,128.928 C164.736,124.672 87.52,82.848 35.648,19.136 C26.592,34.848 21.28,52.832 21.28,72.192 C21.28,108.544 40,140.768 67.904,159.424 C51.04,159.104 34.496,154.208 20.48,146.496 C20.48,146.816 20.48,147.232 20.48,147.648 C20.48,198.656 56.864,241.024 104.576,250.784 C96.032,253.12 86.72,254.24 77.056,254.24 C70.336,254.24 63.552,253.856 57.184,252.448 C70.784,294.016 109.376,324.576 155.264,325.568 C119.552,353.504 74.208,370.336 25.12,370.336 C16.512,370.336 8.256,369.952 -5.68434189e-14,368.896 C46.496,398.88 101.6,416 161.024,416 C354.176,416 459.776,256 459.776,117.312 C459.776,112.672 459.616,108.192 459.392,103.744 C480.224,88.96 497.728,70.496 512,49.248 Z"/>
  </svg>
);

const Svg = source();
const Icon = createSvgIcon(Svg, 'Twitter');
Icon.Svg = Svg;

export default Icon;
