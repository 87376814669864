const Colors = {
  red: {
    default: '#aa2531',
    5: '#110304',
    10: '#220709',
    15: '#330b0e',
    20: '#440e13',
    25: '#551218',
    30: '#66161d',
    35: '#761922',
    40: '#881d27',
    45: '#99212c',
    50: '#aa2531',
    55: '#b23a45',
    60: '#bb505a',
    65: '#c3666e',
    70: '#cc7c83',
    75: '#d49298',
    80: '#dda7ac',
    85: '#e5bdc1',
    90: '#eed3d5',
    95: '#f6e9ea',
  },

  orange: {
    5: '#110903',
    10: '#221207',
    15: '#331b0b',
    20: '#44240e',
    25: '#552e12',
    30: '#663716',
    35: '#764019',
    40: '#88491d',
    45: '#995221',
    50: '#aa5c25',
    55: '#b26c3a',
    60: '#bb7c50',
    65: '#c38c66',
    70: '#cc9d7c',
    75: '#d4ad92',
    80: '#ddbda7',
    85: '#e5cebd',
    90: '#eeded3',
    95: '#f6eee9',
  },

  yellow: {
    5: '#110f03',
    10: '#221f07',
    15: '#332f0b',
    20: '#443f0e',
    25: '#554f12',
    30: '#665f16',
    35: '#766f19',
    40: '#887f1d',
    45: '#998f21',
    50: '#aa9f25',
    55: '#b2a83a',
    60: '#bbb250',
    65: '#c3bb66',
    70: '#ccc57c',
    75: '#d4cf92',
    80: '#ddd8a7',
    85: '#e5e2bd',
    90: '#eeebd3',
    95: '#f6f5e9',
  },

  green: {
    5: '#041103',
    10: '#092207',
    15: '#0e330b',
    20: '#13440e',
    25: '#185512',
    30: '#1d6616',
    35: '#227619',
    40: '#27881d',
    45: '#2c9921',
    50: '#31aa25',
    55: '#45b23a',
    60: '#5abb50',
    65: '#6ec366',
    70: '#83cc7c',
    75: '#98d492',
    80: '#acdda7',
    85: '#c1e5bd',
    90: '#d5eed3',
    95: '#eaf6e9',
  },

  blue: {
    default: '#262652',
    5: '#030308',
    10: '#070710',
    15: '#0b0b18',
    20: '#0f0f20',
    25: '#131329',
    30: '#161631',
    35: '#1a1a39',
    40: '#1e1e41',
    45: '#222249',
    50: '#262652',
    55: '#3b3b63',
    60: '#515174',
    65: '#676785',
    70: '#7c7c97',
    75: '#9292a8',
    80: '#a8a8b9',
    85: '#bdbdcb',
    90: '#d3d3dc',
    95: '#e9e9ed',
  },

  violet: {
    5: '#090311',
    10: '#120722',
    15: '#1b0b33',
    20: '#240e44',
    25: '#2e1255',
    30: '#371666',
    35: '#401976',
    40: '#491d88',
    45: '#522199',
    50: '#5c25aa',
    55: '#6c3ab2',
    60: '#7c50bb',
    65: '#8c66c3',
    70: '#9d7ccc',
    75: '#ad92d4',
    80: '#bda7dd',
    85: '#cebde5',
    90: '#ded3ee',
    95: '#eee9f6',
  },

  grayscale: {
    0: '#000000',
    5: '#0D0D0D',
    10: '#1A1A1A',
    15: '#262626',
    20: '#333333',
    25: '#404040',
    30: '#4D4D4D',
    35: '#595959',
    40: '#666666',
    45: '#737373',
    50: '#7F7F7F',
    55: '#999999',
    60: '#A6A6A6',
    65: '#B3B3B3',
    70: '#BFBFBF',
    75: '#CCCCCC',
    80: '#D9D9D9',
    85: '#E5E5E5',
    90: '#F2F2F2',
    95: '#FCFCFC',
    100: '#FFFFFF',
  },
};

Colors.fontColor = {
  light: Colors.grayscale[25],
  dark: Colors.grayscale[90],
};
Colors.black = Colors.grayscale[5];
Colors.white = Colors.grayscale[100];

export default Colors;
