import React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const source = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 179 239">
    <g fill="none" transform="matrix(1 0 0 -1 .25 238.5)">
      <path fill="#000" d="M11.041,0.8468 C5.2135,0.8468 0.4785,5.5884 0.4785,11.4478 L0.4785,159.5078 C0.4785,165.3578 5.2135,170.1078 11.0405,170.1078 L166.4505,170.1078 C172.2805,170.1078 177.0105,165.3578 177.0105,159.5078 L177.0105,11.4478 C177.0105,5.5922 172.2805,0.8508 166.4505,0.8508 L11.0405,0.8506 L11.041,0.8468 Z M10.943,175.6468 C4.9246,175.6468 0.0508,181.5668 0.0508,188.8568 L0.0508,224.1368 C0.0508,231.4468 4.9246,237.3668 10.9428,237.3668 L166.1128,237.3668 C172.1328,237.3668 176.9928,231.4468 176.9928,224.1368 L176.9928,188.8568 C176.9928,181.5668 172.1328,175.6468 166.1128,175.6468 L10.9428,175.6468 L10.943,175.6468 Z" transform="matrix(1 0 0 -1 0 238.214)"/>
      <path fill="#FFCB05" d="M163.675,233.2625 L13.4,233.2625 C8.27125,233.2625 4.1275,229.1125 4.1275,223.98625 L4.1275,82.53625 C4.1275,77.415 8.27125,73.26 13.4,73.26 L163.675,73.26 C168.80875,73.26 172.95,77.415 172.95,82.53625 L172.95,223.98625 C172.95,229.1125 168.80875,233.2625 163.675,233.2625"/>
      <path fill="#000" d="M158.2125,227.5125 L18.6875,227.5125 C13.57,227.5125 9.415,223.36 9.415,218.2375 L9.415,87.825 C9.415,82.7025 13.57,78.5475 18.6875,78.5475 L158.2125,78.5475 C163.34,78.5475 167.4825,82.7025 167.4825,87.825 L167.4825,218.2375 C167.4825,223.36 163.34,227.5125 158.2125,227.5125"/>
      <path fill="#FFCB05" d="M88.918,5.566 L73.637,54.457 L21.412,54.734 L63.643,83.893 L48.076,132.782 L88.637,103.622 L130.59,133.062 L114.75,83.893 L155.31,54.734 L104.74,54.734 L88.918,5.566 Z M13.566,166.916 C8.4477,166.916 4.3008,171.066 4.3008,176.186 L4.3008,210.256 C4.3008,215.376 8.4477,219.516 13.566,219.516 L164.086,219.516 C169.196,219.516 173.346,215.376 173.346,210.256 L173.346,176.186 C173.346,171.066 169.196,166.916 164.086,166.916 L13.566,166.916 Z" transform="matrix(1 0 0 -1 0 225.082)"/>
      <path fill="#000" d="M88.637,9.654 L78.354,47.708 L41.133,47.431 L70.578,68.542 L59.184,104.928 L88.08,82.708 L120.31,105.768 L106.97,69.931 L137.53,47.708 L99.19,47.708 L88.637,9.654 Z M14.316,158.584 C11.245,158.584 8.754,161.074 8.754,164.144 L8.754,197.024 C8.754,200.094 11.245,202.574 14.316,202.574 L162.706,202.574 C165.786,202.574 168.256,200.094 168.256,197.024 L168.256,164.144 C168.256,161.074 165.786,158.584 162.706,158.584 L14.316,158.584 Z" transform="matrix(1 0 0 -1 0 212.228)"/>
      <path fill="#FFF" d="M88.746,17.141 L81.057,45.586 L53.227,45.381 L75.244,61.153 L66.729,88.352 L88.328,71.742 L112.41,88.982 L102.44,62.194 L125.29,45.586 L96.623,45.586 L88.746,17.141 Z M81.453,161.121 L77.729,188.951 L83.99,188.951 L84.328,183.881 L87.799,183.881 L88.305,188.951 L94.48,188.951 L90.506,161.121 L81.453,161.121 L81.453,161.121 Z M32.043,161.121 L32.043,183.711 L29.592,183.711 L29.592,161.201 L23.244,161.131 L23.244,185.141 L27.307,189.041 L34.496,189.041 L38.389,184.971 L38.389,161.121 L32.043,161.121 L32.043,161.121 Z M136.323,161.161 L141.433,177.191 L141.563,188.991 L147.823,188.991 L147.823,177.191 L153.033,161.161 L146.943,161.161 L144.693,170.891 L142.493,161.161 L136.323,161.161 L136.323,161.161 Z M96.261,161.201 L96.345,189.121 L102.433,189.041 L102.433,177.871 L105.063,177.871 L105.143,189.041 L110.903,189.121 L110.903,177.871 L108.023,175.161 L110.903,172.541 L110.903,165.091 L106.833,161.201 L96.261,161.201 L96.261,161.201 Z M55.483,161.201 L51.716,164.971 L51.716,173.641 L55.714,177.641 L60.388,177.641 L60.388,183.621 L57.849,183.621 L57.849,180.071 L51.589,180.071 L51.589,185.061 L55.567,189.041 L62.503,189.041 L66.565,184.971 L66.565,176.431 L62.419,172.291 L57.939,172.291 L57.939,166.451 L60.22,166.451 L60.304,170.081 L66.312,170.081 L66.312,165.091 L62.081,161.201 L55.483,161.201 Z M126.803,161.201 L124.603,174.571 L122.313,161.291 L115.043,161.291 L114.963,189.041 L120.633,189.041 L120.633,171.351 L123.333,189.041 L125.783,189.041 L128.493,171.351 L128.493,189.041 L134.253,189.041 L134.083,161.201 L126.803,161.201 Z M69.78,183.711 L69.862,188.951 L75.956,188.951 L75.956,183.711 L69.78,183.711 L69.78,183.711 Z M41.944,183.791 L41.862,189.041 L48.038,189.041 L48.038,183.791 L41.944,183.791 Z" transform="matrix(1 0 0 -1 0 206.262)"/>
      <path fill="#000" d="M102.43,27.71 L102.43,33.8 L105.06,33.72 L105.06,27.71 L102.43,27.71 Z M85.936,28.3 L84.834,39.73 L87.375,39.73 L85.936,28.3 Z" transform="matrix(1 0 0 -1 0 67.44)"/>
    </g>
  </svg>
);

const Svg = source();
const Icon = createSvgIcon(Svg, 'Army');
Icon.Svg = Svg;

export default Icon;
